import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Sidebar from '../components/Sidebar'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'

class Contact extends React.Component {
    
  render() {
    const { title } = this.props.data.site.siteMetadata

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`Contact Me | ${title}`}</title>
          </Helmet>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">
              <div className="page">
                <h1 className="page__title">Contact Me</h1>
                <p>If you'd like to get in touch, please use the form below, 
                or feel free to reach out via social media. I'm most
                easily contactable on twitter at <a href="https://twitter.com/chrisgrice">@chrisgrice</a>.</p>

                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          github
        }
      }
    }
  }
`
